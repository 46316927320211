import { HeaderNavModel } from 'models/headerNav.model.ts'

export const HeaderNavData: HeaderNavModel[] = [
  {
    name: 'Studio',
    href: '/studio',
  },
  {
    name: 'Product Advisory',
    href: 'http://product.redfabriq.com/',
    isExternalLink: true,
  },
  {
    name: 'Portfolio',
    href: '/portfolio',
  },
  // {
  //   name: 'Talents',
  //   href: '/talents',
  // },
  {
    name: 'Jobs',
    href: '/jobs',
  },
  {
    name: 'Contact',
    href: '/contact',
  },
]
